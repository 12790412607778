export enum ProjectPageWidgetComponentIds {
  Root = '#projectPageWidgetRoot',
  NavigationWidget = '#navigationWidget',
  MultiStateBox = '#projectMultiStateBox',
  ProjectWidget = '#projectWidget',
}

export enum ProjectPageWidgetStates {
  Project = 'projectState',
}

export enum ProjectPageWidgetEvents {
  // Change = 'change',
}

import model from './model';
import { ProjectWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectWidgetProps as Props } from './config/types';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/build/es/types';
import {
  serverItemsToProGallery,
  proGalleryItemsToWixCode,
} from '@wix/pro-gallery-items-formatter';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    projectHeaderWidget: $w(ComponentIds.Header),
    gallery: $w(ComponentIds.Gallery),
  });

  const getWixCodePGItems = (items: Item[]) => {
    const projetsImages = items.map((item) => {
      return {
        ...item,
        dataType: 'Photo',
      };
    });
    const pgItems = serverItemsToProGallery(projetsImages);
    const wixCodeItems = proGalleryItemsToWixCode(pgItems);

    return wixCodeItems;
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.project.id) {
      const components = getComponents();
      components.projectHeaderWidget.data = {
        title: data.project.title,
        subtitle: data.project.subtitle,
        description: data.project.description,
        heroImage: data.project.coverImage,
      };
      components.gallery.items = getWixCodePGItems(data.items);
    }
  };

  return {
    pageReady: async () => {
      // initByProps($widget.props);
      $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});

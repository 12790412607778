import model from './model';
import { CategoryPageWidgetComponentIds as ComponentIds } from './config/constants';
import { CategoryPageWidgetProps as Props } from './config/types';
import { listProjectsByCategory } from '@wix/ambassador-portfolio-projects-v1-project/http';
import { ProjectInCategory } from '@wix/ambassador-portfolio-projects-v1-project/types';
import { getCategory } from '@wix/ambassador-portfolio-categories-v1-category/http';
import { Category } from '@wix/ambassador-portfolio-categories-v1-category/build/es/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;

  const constCategoryId = '3c403ef2-a5a9-4321-8290-7e86adb20dae';
  let currCategory: Category = {};
  let projectsInCategory: ProjectInCategory[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    navigationWidget: $w(ComponentIds.NavigationWidget),
    categoryWidget: $w(ComponentIds.CategoryWidget),
  });

  const getCategoryIdFromUrl = () => {
    // flowAPI.
    return constCategoryId;
  };

  const getCategoryById = async (categoryId) => {
    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(getCategory({ categoryId }))
        .then((res) => {
          const {
            data: { category = {} },
          } = res;

          return category;
        })
    );
  };

  const getProjects = async (categoryId) => {
    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(listProjectsByCategory({ categoryId }))
        .then((res) => {
          const {
            data: { projects = [] },
          } = res;

          return projects;
        })
    );
  };

  const initCategory = async () => {
    const categoryId = getCategoryIdFromUrl();
    // await all
    currCategory = await getCategoryById(categoryId);
    projectsInCategory = await getProjects(currCategory.id);
    const components = getComponents();
    components.categoryWidget.data = {
      category: currCategory,
      projects: projectsInCategory.map(p => p.project),
    };
  };

  return {
    pageReady: async () => {
      await initCategory();
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});

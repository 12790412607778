import model from './model';
import { CategoriesGalleryWidgetComponentIds as ComponentIds } from './config/constants';
import { CategoriesGalleryWidgetProps as Props } from './config/types';
import {
  serverItemsToProGallery,
  proGalleryItemsToWixCode,
} from '@wix/pro-gallery-items-formatter';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    galleryWidget: $w(ComponentIds.GalleyWIdget),
  });

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.categories) {
      const categoryCoverImages = data.categories.map((category) => {
        return {
          ...category.coverImage,
          dataType: 'Photo',
        };
      });
      const serverItems = serverItemsToProGallery(categoryCoverImages);
      const wixCodeServerItems = proGalleryItemsToWixCode(serverItems);
      getComponents().galleryWidget.items = wixCodeServerItems;
    }
  };

  return {
    pageReady: async () => {
      console.log($widget.props);
      $widget.fireEvent('widgetLoaded', {});
      // initByProps($widget.props);
      $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));
    },
    exports: {},
  };
});

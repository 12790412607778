import model from './model';
import { ProjectHeaderWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectHeaderWidgetProps as Props } from './config/types';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    subtitle: $w(ComponentIds.SubTitle),
    description: $w(ComponentIds.SubTitle),
    image: $w(ComponentIds.HeroImage),
  });

  const initByProps = (props: Props) => {
    const { data } = props;
    const components = getComponents();
    components.title.text = data.title;
    components.subtitle.text = data.subtitle;
    // components.description = data.description;
    components.image.src = data.heroImage.mediaUrl;
  };

  return {
    pageReady: async () => {
      // initByProps($widget.props);
      $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});

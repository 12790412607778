import model from './model';
import { ProjectPageWidgetComponentIds as ComponentIds } from './config/constants';
import { ProjectPageWidgetProps as Props } from './config/types';
import { getProject } from '@wix/ambassador-portfolio-projects-v1-project/build/es/http';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/build/es/types';
import { listProjectItems } from '@wix/ambassador-portfolio-project-items-v1-project-item/http';
import { ProjectItem } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';


export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;

  const constProjectId = '05425b19-fe92-4047-9524-38ac581a564c';
  let currProject: Project = {};
  let currProjectItems: ProjectItem[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    navigationWidget: $w(ComponentIds.NavigationWidget),
    multiStateBox: $w(ComponentIds.MultiStateBox),
    projectWidget: $w(ComponentIds.ProjectWidget),
  });

  const getProjectIdFromUrl = () => {
    // flowAPI.
    return constProjectId;
  };

  const getProjectById = async (projectId) => {
    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(getProject({ projectId }))
        .then((res) => {
          const {
            data: { project = {} },
          } = res;

          return project;
        })
    );
  };

  const getProjectItems = async (projectId) => {
    return (
      flowAPI.httpClient
        // @ts-expect-error
        .request(listProjectItems({ projectId }))
        .then((res) => {
          const {
            data: { projectItems = [] },
          } = res;

          return projectItems;
        })
    );
  };

  const initProject = async () => {
    const projectId = getProjectIdFromUrl();
    // await all
    currProject = await getProjectById(projectId);
    currProjectItems = await getProjectItems(currProject.id);
    const components = getComponents();
    components.projectWidget.data = {
      project: currProject,
      items: currProjectItems.map((item) => item.item),
    };
  };

  return {
    pageReady: async () => {
      await initProject();
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});

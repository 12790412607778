import model from './model';
import { CategoryWidgetComponentIds as ComponentIds } from './config/constants';
import { CategoryWidgetProps as Props } from './config/types';
import { Project } from '@wix/ambassador-portfolio-projects-v1-project/build/es/types';
import {
  serverItemsToProGallery,
  proGalleryItemsToWixCode,
} from '@wix/pro-gallery-items-formatter';

export default model.createController((controllerParams) => {
  const { $w, $widget } = controllerParams;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    title: $w(ComponentIds.Title),
    description: $w(ComponentIds.Description),
    gallery: $w(ComponentIds.Gallery),
  });

  const getWixCodePGItems = (projects: Project[]) => {
    const categoryCoverImages = projects.map((project) => {
      return {
        ...project.coverImage,
        dataType: 'Photo',
      };
    });
    const pgItems = serverItemsToProGallery(categoryCoverImages);
    const wixCodeItems = proGalleryItemsToWixCode(pgItems);

    return wixCodeItems;
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.category.id) {
      const components = getComponents();
      components.title.text = data.category.title;
      components.description.text = data.category.description;
      components.gallery.items = getWixCodePGItems(data.projects);
    }
  };

  return {
    pageReady: async () => {
      // initByProps($widget.props);
      $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));
      $widget.fireEvent('widgetLoaded', {});
    },
    exports: {},
  };
});

export enum ProjectHeaderWidgetComponentIds {
  Root = '#projectHeaderWidgetRoot',
  Title = '#projectTitle',
  SubTitle = '#projectSubtitle',
  Description = '#projectDescription',
  HeroImage = '#projectHeroImage',
}

export enum ProjectHeaderWidgetEvents { }
// Change = 'change',
